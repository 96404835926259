@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300&family=Sniglet&display=swap');


@font-face {
  font-family: SFPRO;
  src: url(../public/fonts/sfpro.ttf);
}

@font-face {
  font-family: SF;
  src: url(../public/fonts/sf.ttf);
}


@font-face {
  font-family: SNIGLET;
  src: url(../public/fonts/Sniglet-Regular.ttf);
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'SFPRO';  text-align: center;
  /* background: #000; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.sf-font {
  font-family: 'SF';
}

.sn-font{
  font-family: 'SNIGLET';
}

.snc-font{
  font-family: 'SeoulNamsan';
}

.slide-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
}


.rc-accordion-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between; 
  transition: 0.3s;
}

.rc-accordion-toggle.active .rc-accordion-icon{
  transform: rotate(180deg);
  color: #fff;
}

.rc-accordion-card:last-child{
  margin-bottom: 0;
}

.rc-accordion-title{
  font-weight: 500;
  font-size: 14px;
  position: relative;
  margin-bottom: 0;
  color: #475F7B;
  transition: 0.3s;
}

.rc-accordion-toggle.active .rc-accordion-title{
  color: #fff;
}

.rc-accordion-icon{
  position: relative;
  top: 2px;
  color: #475F7B;
  transition: 0.35s;
  font-size: 12px;
}

.rc-accordion-body{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}

.rc-collapse{
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.rc-collapse.show{
  height: auto;
}


.spinner {
  animation: 2s linear infinite svg-animation;
  max-width: 100px;
}


@keyframes svg-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}


circle {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: #FFF;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 280;
  stroke-width: 10px;
  transform-origin: 50% 50%;
}


@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }
  
  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}